import React from "react";
import "./footer.css";
import { FiLinkedin } from "react-icons/fi";
import { BsInstagram } from "react-icons/bs";
import { BsEnvelope } from "react-icons/bs";
import Logo from "../../assets/logo.png";

function Footer() {
  return (
    <footer>
      <div className="container">
        <a href="#" className="footer__logo">
          <img src={Logo} />
        </a>
        <div className="footer__socials">
          <a
            href="https://www.linkedin.com/in/danl28/"
            target="_blank"
            rel="noreferrer"
          >
            <FiLinkedin />
          </a>
          <a
            href="https://www.instagram.com/studio.danew/"
            target="_blank"
            rel="noreferrer"
          >
            <BsInstagram />
          </a>
          <a
            href="mailto:hello@danthedev.digital"
            target="_blank"
            rel="noreferrer"
          >
            <BsEnvelope />
          </a>
        </div>
        <div className="footer__copyright">
          <p>
            Coded on{" "}
            <a href="https://code.visualstudio.com/" target="_blank">
              Visual Studio Code
            </a>{" "}
            and built with{" "}
            <a href="https://react.dev/" target="_blank">
              React
            </a>{" "}
            | All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
