import React from "react";
import "./portfolio.css";
import myData from "./portfolioData";
import { BiArrowToRight } from "react-icons/bi";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "swiper/css/grid";

// import required modules
import { Grid, Pagination } from "swiper";

function Portfolio(props) {
  // put datafile into an array
  const [projectsData, setProjectsData] = React.useState(myData);
  // set active state to project menu buttons
  const [activeProjects, setProjectsActive] = React.useState("All");

  function filterProjects(category) {
    if (category === "All") {
      setProjectsData(myData);
      setProjectsActive(category);
    } else {
      const filterProjects = myData.filter(
        (data) => data.category === category
      );
      setProjectsData(filterProjects);
      setProjectsActive(category);
    }
  }

  return (
    <section id="portfolio" ref={props.setPortfolio}>
      <div className="container">
        <h5
          className={
            props.setPortfolioAnimate ? "heading-animate" : "section-animateout"
          }
        >
          What Have I Been Up to
        </h5>
        <h2
          className={
            props.setPortfolioAnimate ? "heading-animate" : "section-animateout"
          }
        >
          My Recent Projects
        </h2>
      </div>
      <div
        className={`container portfolio__container ${
          props.setPortfolioAnimate ? "panel-animate" : "section-animateout"
        }`}
      >
        <div className="portfolio__menu">
          <ul>
            <li
              className={`portfolio__menu-btn ${
                activeProjects === "All" ? "active" : null
              }`}
              onClick={() => filterProjects("All")}
            >
              All
            </li>
            <li
              className={`portfolio__menu-btn ${
                activeProjects === "Corporate" ? "active" : null
              }`}
              onClick={() => filterProjects("Corporate")}
            >
              Corporate
            </li>
            <li
              className={`portfolio__menu-btn ${
                activeProjects === "Freelance" ? "active" : null
              }`}
              onClick={() => filterProjects("Freelance")}
            >
              Freelance
            </li>
            <li
              className={`portfolio__menu-btn ${
                activeProjects === "Other" ? "active" : null
              }`}
              onClick={() => filterProjects("Other")}
            >
              Other
            </li>
          </ul>
        </div>

        <Swiper
          // slidesPerView={4}
          breakpoints={{
            // when window width is >= 1200px
            1200: {
              slidesPerView: 4,
            },
            // when window width is 1024px - 1199px
            1024: {
              slidesPerView: 4,
            },
            // when window width is 768px - 1023px
            768: {
              slidesPerView: 3,
            },
            // when window width is 480px - 767px
            480: {
              slidesPerView: 2,
            },
          }}
          grid={{
            rows: 2,
          }}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Grid, Pagination]}
          className="mySwiper"
        >
          {projectsData.map((projectData) => {
            const { id, image, title, description, website } = projectData;
            return (
              <SwiperSlide
                key={id}
                className="portfolio__item"
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="portfolio__item-cta">
                  <h3>{title}</h3>
                  <small className="text-light">{description}</small>
                  <a
                    href={website}
                    rel="noreferrer"
                    target="_blank"
                    className="portfolio__item-btn"
                  >
                    <small>View website</small>
                    <BiArrowToRight className="portfolio__item-cta-icon" />
                  </a>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
}

export default Portfolio;
